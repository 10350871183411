import React from "react"

import { Typography, makeStyles } from "@material-ui/core";
import Link from '@material-ui/core/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

import SEO from "../components/seo";
import Layout from '../components/Layout';

const useStyles = makeStyles(theme => ({
	layout: {
		[theme.breakpoints.down("sm")]: {
			margin: 10
		},
		[theme.breakpoints.up("md")]: {
			margin: 20
		}
	},
	nav: {
		maxWidth: theme.breakpoints.width('sm'),
		justifyContent: "space-evenly",
		display: "flex",
		margin: "auto",
		marginTop: 8
	},
	body: {
		marginTop: 48
	}
}));

const IndexPage = () => {
	const classes = useStyles();

	return (
		<Layout>
			<div className={classes.root}>
				<SEO title="Nick Clarity" />
				<Typography variant="h1" color="primary">Nick Clarity</Typography>
				<Typography variant="h2">Full Stack Developer</Typography>
				<nav className={classes.nav}>
					<Link color="inherit" className={classes.link} target="_blank" href="https://www.linkedin.com/in/nicholas-clarity/">
						<FontAwesomeIcon size="3x" icon={faLinkedin} />
					</Link>
					<Link color="inherit" className={classes.link} target="_blank" href="https://github.com/nickheartrobots">
						<FontAwesomeIcon size="3x" icon={faGithub} />
					</Link>
					<Link color="inherit" className={classes.link} target="_blank" href="https://twitter.com/nick_robot">
						<FontAwesomeIcon size="3x" icon={faTwitter} />
					</Link>
					<Link color="inherit" className={classes.link} target="_blank" href="https://www.instagram.com/nickheartrobots/">
						<FontAwesomeIcon size="3x" icon={faInstagram} />
					</Link>
				</nav>
				<div className={classes.body}>
					<Typography variant="body1">
						I am a senior full-stack software developer specializing in C#/.Net, React, and SQL Server with a bachelor's degree in Computer Science.
                        I've worked on several different projects and teams building client-side, responsive web apps with React and building robust RESTful APIs with .Net Core.
					</Typography>
					<br/>
					<Typography variant="body1">
						I've lead small front-end teams as well as lead large refactoring efforts across legacy applications and many distributed services.
                        One of my favorite projects included redesigning and implementing a policy-based authorization system, which I refactored from a typical role-based system
					</Typography>
					<br/>
					<Typography variant="body1">
						Currently, I am working to implement Clean Architecture and Domain Driven Design patterns into existing applications.
                        Working to build towards robust and resilient applications that can easily be scaled and maintained independently.
						I've also been consuming a lot of architecture blogs, conference talks, and reading <Link target="_blank" href="https://www.oreilly.com/library/view/clean-architecture-a/9780134494272/">Clean Architecture: A Craftsman's Guide to Software Structure and Design</Link> in preparation for my journey to software architect someday.
					</Typography>
				</div>
			</div>
		</Layout>
	);
}

export default IndexPage
